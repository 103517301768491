import Counters from "../../components/Home/Counters";
import Campus from "../../components/Home/Campus";
import Hero from "../../components/Home/Hero";
import Highlights from "../../components/Home/Highlights";
import Layout from "../../components/Layouts/Layout";
import SponsorList from "../../components/Sponsor/SponsorList";
import Activities from "../../components/Home/Activities";
import Title from "../../components/Title/Title";
import MediaPartners from "../../components/Home/MediaPartners";
import CommunityPartners from "../../components/Home/CommunityPartners";
import TechnicalCollaborators from "../../components/Home/TechnicalCollaborators";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import CVUploader from "../../components/Home/CVUploader";
// import EntertainmentModal from "../../components/Home/EntertainmentModal";
import Timeline from "../../components/Home/Timeline";
import Conference from "../../components/Home/Conference";

const Home = () => {
    return (
        <Layout title="Home" size="8xl">
            {/* <EntertainmentModal /> */}
            <ToastContainer />
            <div className="homepage scroll-smooth">
                <Hero />
                <Timeline />

                <Highlights />

                <Conference />

                <Activities />
                <Counters />
                <Campus />
                <Title underline text="Our Sponsors" />
                <SponsorList cols={3} />
                <MediaPartners />
                <CommunityPartners />
                <TechnicalCollaborators />
            </div>
            {/* <CVUploader /> */}
        </Layout>
    );
};

export default Home;

import { useEffect, useState } from "react";
import Title from "../Title/Title"
const timeline = {
    day1: [
        {
            id: 1,
            title: "Competitions Start",
            description: "Free Hand Sketching, Query Master",
            time: "03:30 PM",
        },
        {
            id: 2,
            title: "WorkShop",
            description: "GDG Cloud Lahore",
            time: "03:30 PM - 04:30 PM",
        },
        {
            id: 3,
            title: "WorkShop",
            description: "Women Tech Makers",
            time: "04:30 PM - 05:15 PM",
        },
        {
            id: 4,
            title: "WorkShop",
            description: "MLSA",
            time: "05:20 PM - 06:05 PM",
        },
        {
            id: 5,
            title: "Dinner Boxes",
            description: "For Team and Participants",
            time: "08:00 PM - 10:00 PM",
        },
    ],
    day2: [
        {
            id: 1,
            title: "Breakfast",
            description: "",
            time: "07:00 AM - 08:00 AM",
        },
        {
            id: 2,
            title: "Reception/Check-in",
            description: "",
            time: "08:00 AM - 08:45 AM",
        },
        {
            id: 3,
            title: "Opening Ceremony",
            description: "",
            time: "08:30 AM - 09:30 AM",
        },
        {
            id: 4,
            title: "Software House Enclosure",
            description: "",
            time: "10:30 AM - 5:00 PM",
        },
        {
            id: 5,
            title: "Competitions Start",
            description: "UI/UX, IX, GDC, PC Round 1 Slot-1",
            time: "10:30 AM",
        },
        {
            id: 6,
            title: "Evaluations Start",
            description: "EPC, SPC",
            time: "10:30 AM",
        },
        {
            id: 7,
            title: "Competitions Start",
            description: "WebDev, FIFA, Tekken, Valorent, CAD War",
            time: "11:00 AM",
        },
        {
            id: 8,
            title: "Evaluation Starts",
            description: "AI Competition",
            time: "11:00 AM",
        },
        {
            id: 9,
            title: "Competitions Start",
            description: "AppDev, Data Visualization Round 1",
            time: "12:00 PM",
        },
        {
            id: 10,
            title: "Panel Talk",
            description: "Future of AI in Pakistan",
            time: "12:00 PM - 02:00 PM",
        },
        {
            id: 11,
            title: "Lunch",
            description: "For Judges, Team, Sponsors and Participants",
            time: "01:00 PM",
        },
        {
            id: 12,
            title: "Competitions Start",
            description: "Bridge, Blockchain, Free Hand Sketching",
            time: "02:00 PM",
        },
        {
            id: 13,
            title: "Evaluations Start",
            description:
                "Free Hand Sketching (Participant Presence is not necessary)",
            time: "02:00 PM",
        },
        {
            id: 14,
            title: "Evaluations End",
            description: "EPC, SPC",
            time: "03:00 PM",
        },
        {
            id: 15,
            title: "Competition Starts",
            description: "PC Round 1 Slot 2",
            time: "03:00 PM",
        },
        {
            id: 16,
            title: "Evaluation Starts",
            description: "GDC",
            time: "03:30 PM",
        },
        {
            id: 17,
            title: "Closing Ceremony",
            description: "Ideas Xtreme",
            time: "05:00 PM",
        },
        {
            id: 18,
            title: "Dinner",
            description: "For Team & Participants",
            time: "07:00 PM",
        },
        {
            id: 19,
            title: "Entertainment Eve",
            description: "Qawali & DJ Night",
            time: "07:00 PM",
        },
    ],
    day3: [
        {
            id: 1,
            title: "Breakfast",
            description: "",
            time: "07:00 AM - 08:00 AM",
        },
        {
            id: 2,
            title: "Conference",
            description: "AWS",
            time: "09:00 AM - 01:00 PM",
        },
        {
            id: 3,
            title: "Evaluation Starts",
            description: "EPC, SPC",
            time: "10:30 AM - 5:00 PM",
        },
        {
            id: 4,
            title: "Software House Enclosure",
            description: "",
            time: "10:30 AM - 5:00 PM",
        },
        {
            id: 5,
            title: "Competitions Continue",
            description: "FIFA, Tekken, Valorent",
            time: "11:00 AM",
        },
        {
            id: 6,
            title: "Competitions Start",
            description: "PC Round 2, Cyber Security, DV Round 2",
            time: "11:00 AM",
        },
        {
            id: 7,
            title: "Evaluations Start",
            description: "UI/UX, Blockchain",
            time: "11:00 AM",
        },
        {
            id: 8,
            title: "Competitions Continue",
            description: "FIFA, Tekken, Data Visualization Round-2",
            time: "11:00 AM",
        },
        {
            id: 9,
            title: "Evaluations Start",
            description: "UI/UX, Blockchain",
            time: "11:00 AM",
        },
        {
            id: 10,
            title: "Competition Starts",
            description: "Robo Rumble",
            time: "12:00 PM",
        },
        {
            id: 11,
            title: "Evaluation Starts",
            description: "WebDev, Game Jam",
            time: "12:30 PM",
        },
        {
            id: 12,
            title: "Lunch",
            description: "For Judges, Sponsors and Participants",
            time: "01:00 PM",
        },
        {
            id: 13,
            title: "Evaluations Start",
            description: "AppDev",
            time: "02:00 PM",
        },
        {
            id: 14,
            title: "Workshop",
            description: "Git & GitHub 101",
            time: "02:00 PM - 03:00 PM",
        },

        {
            id: 15,
            title: "Evaluations End",
            description: "EPC, SPC",
            time: "03:00 PM",
        },
        {
            id: 16,
            title: "Evaluations Start",
            description: "Data Visualization",
            time: "04:00 PM",
        },
        {
            id: 17,
            title: "Closing Ceremony",
            description: "",
            time: "07:00 PM",
        },
    ],
};

const Timeline = () => {
    const [active, setActive] = useState("day1");

    useEffect(() => {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth();
        const year = today.getFullYear();

        if (year === 2024) {
            if (day === 23 && month === 1) {
                setActive("day1");
            } else if (day === 24 && month === 1) {
                setActive("day2");
            } else if (day === 25 && month === 1) {
                setActive("day3");
            }
        } else setActive("day1");
    }, []);

    return (
        <section id="timeline">
            <Title text="Event Timeline" underline />
            <div className="md:w-6/12 timeline flex flex-col items-center justify-center w-4/6 mx-auto">
                <Tabs active={active} setActive={setActive} />

                <ol className="border-primary dark:border-primary-500 my-10 border-l-2">
                    {timeline[active].map((item, id) => (
                        <li key={id}>
                            <div className="flex-start flex items-center">
                                <div className="-ml-[9px] -mt-2 mr-3 flex h-4 w-4 items-center justify-center rounded-full bg-primary"></div>
                                <h4 className="-mt-2 text-xl font-semibold">
                                    {item.title}
                                </h4>
                            </div>
                            <div
                                className={`pb-3 mb-6 ml-6 ${
                                    item.id === timeline[active].length &&
                                    "mb-[-15px]"
                                }`}
                            >
                                <span className="text-primary font-bold">
                                    {item.time}
                                </span>
                                {item.description && (
                                    <p className="my-2 text-neutral-700 max-w-[40ch]">
                                        {item.description}
                                    </p>
                                )}
                            </div>
                        </li>
                    ))}
                </ol>

                <div className="lg:flex-row flex flex-col justify-center gap-10 py-10 scale-75">
                    <a
                        download
                        href="/assets/timeline.pdf"
                        className=" text-white rounded-md hover:text-logo-indigo hover:bg-transparent border-logo-indigo border-[2px] px-10 py-4 font-bold hover:rounded-none uppercase bg-logo-indigo focus:outline-none md:text-2xl text-center transition-all"
                    >
                        Download Timeline
                    </a>
                    <a
                        download
                        href="/assets/floorplan.pdf"
                        className=" text-white rounded-md hover:text-logo-indigo hover:bg-transparent border-logo-indigo border-[2px] px-10 py-4 font-bold hover:rounded-none uppercase bg-logo-indigo focus:outline-none md:text-2xl text-center transition-all"
                    >
                        Download Floor Plan
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Timeline;

const Tabs = ({ active, setActive }) => {
    return (
        <div className=" w-full my-6">
            <ul className="flex justify-around font-medium text-center">
                <li className="mr-2">
                    <button
                        className={`inline-block p-4 border-b-2 text-base md:text-lg font-semibold md:font-bold
					${
                        active === "day1"
                            ? "text-logo-indigo border-b-logo-indigo"
                            : "hover:text-gray-600 hover:border-gray-300"
                    }
					border-transparent rounded-t-lg`}
                        onClick={() => setActive("day1")}
                    >
                        Day 1
                    </button>
                </li>
                <li className="mr-2">
                    <button
                        className={`inline-block p-4 border-b-2 text-base md:text-lg font-semibold md:font-bold
					${
                        active === "day2"
                            ? "text-logo-indigo border-b-logo-indigo"
                            : "hover:text-gray-600 hover:border-gray-300"
                    }
					border-transparent rounded-t-lg`}
                        onClick={() => setActive("day2")}
                    >
                        Day 2
                    </button>
                </li>
                <li>
                    <button
                        className={`inline-block p-4 border-b-2 text-base md:text-lg font-semibold md:font-bold
					${
                        active === "day3"
                            ? "text-logo-indigo border-b-logo-indigo"
                            : "hover:text-gray-600 hover:border-gray-300"
                    }
					border-transparent rounded-t-lg`}
                        onClick={() => setActive("day3")}
                    >
                        Day 3
                    </button>
                </li>
            </ul>
        </div>
    );
};

import { useState } from "react";
import { toast } from "react-toastify";
import apiClient from "../../api/apiClient";
import Button from "../../components/Button/Button";
import ContactTile from "../../components/Contact/ContactTile";
import Input from "../../components/Input/Input";
import Layout from "../../components/Layouts/Layout";
import Title from "../../components/Title/Title";
const CONTACTS = [
    {
        id: 1,
        title: "Ambassadors Info",
        name: "Muhammad Hashir",
        position: "Assistant Vice President",
        phone: "0303-6831866",
        phoneLink: "+923036831866",
        email: "hashir@softec.org.pk",
    },
    {
        id: 2,
        title: "Sponsor Info",
        name: "Husnain Asghar",
        position: "Marketing Director",
        phone: "0335-9111737",
        phoneLink: "+923359111737",
        email: "husnain@softec.org.pk",
    },
    {
        id: 3,
        title: "Competitions Info",
        name: "Maham Basit",
        position: "Competitions Coordinator",
        phone: "0335-4388894",
        phoneLink: "+923354388894",
        email: "maham@softec.org.pk",
    },
];
const Contact = () => {
    const [form, setForm] = useState({
        first_name: "",
        last_name: "",
        email: "",
        subject: "",
        message: "",
    });
    const [loading, setLoading] = useState(false);

    function sendMessage(data) {
        return new Promise((resolve, reject) => {
            apiClient
                .post("/contact/", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (
            !(
                form.first_name &&
                form.last_name &&
                form.email &&
                form.subject &&
                form.message
            )
        ) {
            toast.error("Please Fill All Fields");
            return;
        }
        setLoading(true);
        const response = await sendMessage(form);
        if (response?.status === 200 || response?.status === 304) {
            toast.success("Message Sent Successfully");
            setForm({
                first_name: "",
                last_name: "",
                email: "",
                subject: "",
                message: "",
            });
        } else {
            toast.error("Something Went Wrong");
        }
        setLoading(false);
    };

    return (
        <>
            <Layout size={6} title="Contact">
                <Title underline text="Contact Information" />
                <div className="md:flex-row md:px-2 lg:justify-between md:gap-4 flex flex-col flex-wrap justify-center gap-8 px-10">
                    {CONTACTS.map((contact) => (
                        <ContactTile contact={contact} key={contact.id} />
                    ))}
                </div>
                <Title className="my-5" text="Have Something to Say?" />

                <form className="px-5" onSubmit={handleSubmit}>
                    <div className="md:grid-cols-2 md:gap-6 grid">
                        <Input
                            text="First Name"
                            value={form.first_name}
                            name="first_name"
                            placeholder="John"
                            onChange={handleChange}
                            required
                        />

                        <Input
                            text="Last Name"
                            value={form.last_name}
                            name="last_name"
                            placeholder="Doe"
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="md:grid-cols-2 md:gap-6 grid">
                        <Input
                            text="Subject"
                            value={form.subject}
                            name="subject"
                            placeholder="Query about SOFTEC"
                            onChange={handleChange}
                            required
                        />

                        <Input
                            text="Email"
                            type="email"
                            value={form.email}
                            name="email"
                            placeholder="john@mail.com"
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <Input
                        text="Message"
                        type="textarea"
                        value={form.message}
                        name="message"
                        placeholder="Your message..."
                        onChange={handleChange}
                        required
                    />

                    <div className="my-10">
                        <Button loading={loading} text="Send Message" />
                    </div>
                </form>
            </Layout>
            <div className="my-5">
                <iframe
                    title="Map"
                    height="600"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13607.668560810213!2d74.27661824911654!3d31.498961304052212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391903f08ebc7e8b%3A0x47e934f4cd34790!2sFAST%20NUCES%20Lahore%20Campus!5e0!3m2!1sen!2s!4v1580080747006!5m2!1sen!2s"
                    width="100%"
                ></iframe>
            </div>
        </>
    );
};

export default Contact;

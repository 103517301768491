import Layout from "../../components/Layouts/Layout";
import SponsorList from "../../components/Sponsor/SponsorList";
import Title from "../../components/Title/Title";

const Sponsors = () => {
    return (
        <Layout size="8xl" title="Sponsors">
            <Title underline text="Sponsors for SOFTEC 2024" />
            <SponsorList />
        </Layout>
    );
};

export default Sponsors;

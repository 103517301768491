import ImageBox from "../../components/Image/ImageBox";
import Layout from "../../components/Layouts/Layout";
import Title from "../../components/Title/Title";
import apiClient from "../../api/apiClient";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";

const OurTeam = () => {
    // fetch hof from api
    const { data: Team, isLoading } = useQuery(
        ["ExeTeam"],
        async () => {
            const result = await apiClient.get("/exeTeam/");
            return result.data;
        },
        {
            cacheTime: 3600000, // 1 hour
        }
    );

    return (
        <Layout size={7} title="Executive Team">
            <Title underline text="Our Executive Team" />
            <Loader isLoading={isLoading}>
                <div className="flex flex-wrap justify-center gap-6">
                    {!!Team &&
                        Team.map((member) => (
                            <ImageBox
                                imageClass="h-[450px]"
                                key={member.id}
                                member={member}
                            />
                        ))}
                </div>
            </Loader>
        </Layout>
    );
};

export default OurTeam;

import { useState, useRef } from "react";
import Layout from "../../components/Layouts/Layout";
import Title from "../../components/Title/Title";
import RadioList from "../../components/Radio/RadioList";
import HeroImage from "../../components/Image/HeroImage";
import apiClient from "../../api/apiClient";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";

const Exhibitions = () => {
    const [selectedExhibition, setSelectedExhibition] = useState(1);
    const targetElement = useRef();

    const handleClick = (id) => {
        setSelectedExhibition(id);
        targetElement.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    };

    //fetch exhibitions from api
    const { data: Exhibitions, isLoading } = useQuery(
        ["exhibitions"],
        async () => {
            const response = await apiClient.get("/exhibitions/");
            return response.data;
        },
        {
            cacheTime: 300000, // 5 minutes
        }
    );

    const selected = Exhibitions?.find(
        (exhibition) => exhibition.id === selectedExhibition
    );

    return (
        <Layout size={7} title="Exhibitions">
            <Title underline text="Exhibitions" />
            <Loader isLoading={isLoading}>
                <RadioList
                    name="Exhibition"
                    list={Exhibitions}
                    selected={selectedExhibition}
                    handleClick={handleClick}
                />
                <div
                    className="scroll-my-20 flex flex-col justify-center py-4"
                    ref={targetElement}
                >
                    <HeroImage selected={selected} />
                </div>
            </Loader>
        </Layout>
    );
};

export default Exhibitions;

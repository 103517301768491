const Title = ({ underline, text }) => {
    return (
        <h1
            className={`py-10 px-5 mt-10 mb-6 text-2xl font-bold text-center md:text-4xl text-primary ${
                underline && "underline"
            } `}
        >
            {text || "SOFTEC"}
        </h1>
    );
};

export default Title;

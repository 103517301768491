import { useState } from "react";
import FsLightbox from "fslightbox-react";
import Title from "../Title/Title";

const ACTIVITIES = [
    {
        id: 1,
        image: "/assets/activities/small/1.webp",
    },
    {
        id: 2,
        image: "/assets/activities/small/2.webp",
    },
    {
        id: 3,
        image: "/assets/activities/small/3.webp",
    },
    {
        id: 4,
        image: "/assets/activities/small/4.webp",
    },
    {
        id: 5,
        image: "/assets/activities/small/5.webp",
    },
    {
        id: 6,
        image: "/assets/activities/small/6.webp",
    },
    {
        id: 7,
        image: "/assets/activities/small/7.webp",
    },
    {
        id: 8,
        image: "/assets/activities/small/8.webp",
    },
    {
        id: 9,
        image: "/assets/activities/small/9.webp",
    },
    {
        id: 10,
        image: "/assets/activities/small/10.webp",
    },
    {
        id: 11,
        image: "/assets/activities/small/11.webp",
    },
    {
        id: 12,
        image: "/assets/activities/small/12.webp",
    },
    {
        id: 13,
        image: "/assets/activities/small/13.webp",
    },
    {
        id: 14,
        image: "/assets/activities/small/14.webp",
    },
    {
        id: 15,
        image: "/assets/activities/small/15.webp",
    },
    {
        id: 16,
        image: "/assets/activities/small/16.webp",
    },
    {
        id: 17,
        image: "/assets/activities/small/17.webp",
    },
    {
        id: 18,
        image: "/assets/activities/small/18.webp",
    },
];

const Activities = () => {
    const [toggle, setToggle] = useState(false);
    const [img, setImg] = useState("");

    // images, id and smaller version must have same name
    const handleClick = (id) => {
        setToggle(!toggle);
        setImg(`/assets/activities/${id}.webp`);
    };

    return (
        <section className="">
            <Title text="Our Highlights" underline />

            <div className="flex flex-wrap w-full">
                {ACTIVITIES.map((activity) => (
                    <div
                        className="w-[calc(100%/2)] md:w-[calc(100%/6)] relative aspect-[3/2] cursor-pointer group"
                        onClick={() => handleClick(activity.id)}
                        key={activity.id}
                    >
                        <img
                            src={activity.image}
                            alt={activity.image}
                            className="border-1 border"
                            width={318}
                            height={212}
                            loading="lazy"
                        />
                        <div className="w-full h-full flex justify-center items-center absolute top-0 left-0 z-100 opacity-0 transition-opacity group-hover:bg-hover group-hover:!opacity-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="25"
                                height="25"
                            >
                                <path
                                    d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15zM10 10V7h2v3h3v2h-3v3h-2v-3H7v-2h3z"
                                    fill="#fff"
                                />
                            </svg>
                        </div>
                    </div>
                ))}
            </div>
            <FsLightbox toggler={toggle} type="image" sources={[img]} />
        </section>
    );
};

export default Activities;

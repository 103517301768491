import Title from "../Title/Title";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

const Highlights = () => {
    return (
        <section className="highlights">
            <div className=" flex flex-col items-center">
                <Title underline text="SOFTEC 2023 Highlights" />

                <LiteYouTubeEmbed
                    id="WUmRvyTkAPk"
                    poster="maxresdefault"
                    webp
                    aspectHeight={7}
                    params="rel=0"
                />
            </div>
        </section>
    );
};

export default Highlights;
